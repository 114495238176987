<div class="video-container">
  <div #videoCover (mouseleave)="geometryPositionHover(videoCover)" [ngClass]="category" class="live-container">
    <span class="live-title">{{ videoTitle }}</span>
    <span [innerHTML]="videoSubtitle" class="live-subtitle"></span>
    <div class="live-square"></div>
    <img alt="triangle-img" class="live-triangle" src="/assets/triangle.svg" />
    <div class="live-circle"></div>
    <div class="live-play">
      <mat-icon>play_arrow</mat-icon>
    </div>
  </div>
</div>
