import { QuestionService } from 'src/app/core/question-service/question.service';
import { TestService } from '../../../core/test-service/test.service';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoModule } from '@jsverse/transloco';
import { ProfileService } from '../../../core/profile-service/profile.service';
import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SimulationService } from 'src/app/core/simulation-service/simulation.service';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { TestGeneratorOptions } from 'src/app/core/interfaces/test';
import moment from 'moment';
import { finalize, map, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { NgClass } from '@angular/common';
import { ConfigService } from '../../../core/config/config.service';
import { MatFormField } from '@angular/material/form-field';
import { MatOptgroup, MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { getCatDisplayName } from '../../../core/util/category.util';
import { MatIcon } from '@angular/material/icon';
import { showCat2 } from '../../../core/config/config.util';

@Component({
  selector: 'app-cat2-new-test-dialog',
  templateUrl: './cat2-new-test-dialog.component.html',
  styleUrls: ['./cat2-new-test-dialog.component.scss'],
  standalone: true,
  imports: [NgClass, TranslocoModule, MatFormField, MatOption, MatSelect, MatOptgroup, MatIcon],
})
export class Cat2NewTestDialogComponent implements OnInit {
  cats2 = new Array<string>();

  questions_count_dropdown = [30, 20, 10];
  questions_count: { [key: string]: number } = {};
  DURATION_EACH_QUESTION = 0;

  public catSelected: string;
  public countSelected?: number;
  public readonly showCat2Filter = showCat2();

  constructor(
    private dialogRef: MatDialogRef<Cat2NewTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { cat1: string; mode: string; type: string },
    private simulationService: SimulationService,
    private testService: TestService,
    private router: Router,
    private uiService: UiService,
    private profileService: ProfileService,
    private translate: TranslocoService,
    private toast: ToastrService,
    private question: QuestionService,
    private configService: ConfigService
  ) {
    this.catSelected = this.data.cat1;
  }

  ngOnInit(): void {
    this.configService.getSimulationConfig('_DURATION_EACH_QUESTION').subscribe(duration => (this.DURATION_EACH_QUESTION = duration));

    this.uiService.showSpinner = true;
    forkJoin([this.profileService.getCategories(), this.question.getQuestionCount()])
      .pipe(finalize(() => (this.uiService.showSpinner = false)))
      .subscribe({
        next: ([categories, questions]) => {
          console.log({ questions });
          this.questions_count = questions;
          this.cats2 = Array.from(new Set(categories.filter(cat => cat.startsWith(this.data.cat1)).map(cat => cat.split('.')[1])));
          this.setupCount();
        },
        error: () => {
          this.uiService.errorToast({ title: this.translate.translate('utils.errors.general') });
          this.dialogRef.close();
        },
      });
  }

  setupCount() {
    const question_count = Object.entries(this.questions_count)
      .filter(([key]) => key.startsWith(this.catSelected))
      .map(([_, value]) => +value)
      .reduce((a, b) => a + b, 0);

    if (question_count >= 30) {
      this.questions_count_dropdown = [30, 20, 10];
    } else if (question_count <= 5 && question_count > 0) {
      this.questions_count_dropdown = [question_count];
    } else
      this.questions_count_dropdown = new Array(question_count)
        .fill(0)
        .map((_, i) => i + 1)
        .reverse()
        .filter(i => i % 5 == 0)
        .splice(0, 3);
    this.countSelected = this.questions_count_dropdown[0];
  }

  upperCase(str: string): string {
    return getCatDisplayName(str);
  }

  close() {
    this.dialogRef.close();
  }

  start() {
    this.dialogRef.close();
    if (this.data.mode == undefined) this.basicTest();
    else {
      this.router.navigateByUrl('/extra/' + this.catSelected + '/' + this.data.type);
    }
  }

  basicTest() {
    if (!this.countSelected) {
      return;
    }

    const count = this.countSelected;

    this.uiService.showSpinner = true;

    const question = {
      category_path: this.catSelected,
      count,
      difficulty: 0,
      name: getCatDisplayName(this.catSelected),
    };

    this.simulationService
      .getPoints()
      .pipe(
        map(score => {
          const options: TestGeneratorOptions = {
            duration: this.DURATION_EACH_QUESTION * count,
            type: 'subject',
            name: this.translate.translate('metrics.table.body.kind.test_of', { subject: getCatDisplayName(this.data.cat1) }) + ' ' + moment().format('L'),
            questions_generator: [question],
            score_correct: score.correct,
            score_wrong: score.wrong,
            score_blank: score.blank,
          };
          return options;
        }),
        switchMap(options => this.testService.generate(options)),
        finalize(() => (this.uiService.showSpinner = false))
      )
      .subscribe({
        next: test => {
          this.router.navigateByUrl('test/' + test.instance_id, { state: { test: test } });
        },
        error: () =>
          this.toast.error('Non ci sono sufficenti domande per creare la simulazione, prova con un numero inferiore!', '', {
            progressBar: true,
            closeButton: true,
            positionClass: 'toast-bottom-right',
            timeOut: 10000,
          }),
      });
  }
}
