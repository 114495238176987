import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Meeting } from '../../../core/interfaces/meeting';
import { VideoCategory } from '../../../core/interfaces/video-category';
import { categoryPathToCategory, pathToCategories } from '../../../core/util/category.util';
import { isVideoCategory } from '../../../core/util/video.util';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-video-cover',
  templateUrl: './video-cover.component.html',
  styleUrls: ['./video-cover.component.scss'],
  standalone: true,
  imports: [MatIcon, NgClass],
})
export class VideoCoverComponent implements OnChanges, AfterViewInit {
  @Input() public videoTitle?: string;
  @Input() public videoSubtitle?: string;
  @Input() public category = '';

  @Input() media?: VideoCategory | Meeting;

  @ViewChild('videoCover') private videoCover?: ElementRef<HTMLElement>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.media?.currentValue) {
      this.initComponentWithMedia(changes?.media?.currentValue);
    }
  }

  ngAfterViewInit(): void {
    this.changeFontSize();
    this.customGeometry();
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.changeFontSize();
  }

  public geometryPositionHover(cover: HTMLElement): void {
    const square_translate_now = +cover?.style.getPropertyValue('--square-translate').replace('%', '');

    const square_translate_x = this.addNoise(square_translate_now, 15);
    const square_translate_y = this.addNoise(0, 15);

    const triangle_translate_now = +cover?.style.getPropertyValue('--triangle-translate').replace('%', '');

    const triangle_translate_x = this.addNoise(triangle_translate_now, 15);
    const triangle_translate_y = this.addNoise(0, 15);

    const circle_translate_x = this.addNoise(0, 15);
    const circle_translate_y = this.addNoise(0, 15);

    cover.style.setProperty('--square-translate-x', square_translate_x + '%');
    cover.style.setProperty('--square-translate-y', square_translate_y + '%');

    cover.style.setProperty('--triangle-translate-x', triangle_translate_x + '%');
    cover.style.setProperty('--triangle-translate-y', triangle_translate_y + '%');

    cover.style.setProperty('--circle-translate-x', circle_translate_x + '%');
    cover.style.setProperty('--circle-translate-y', circle_translate_y + '%');
  }

  private initComponentWithMedia(media: VideoCategory | Meeting): void {
    let title: string;
    let subtitleBuilder: Array<string>;
    if (isVideoCategory(media)) {
      [title, subtitleBuilder] = this.initFromVideoCategory(media);
    } else {
      [title, subtitleBuilder] = this.initFromMeeting(media);
    }

    const subtitle = this.createSubtitle(subtitleBuilder, this.category);

    this.videoTitle = categoryPathToCategory(title);
    this.videoSubtitle = subtitle?.length ? subtitle.join('<br />') : '';
  }

  private initFromVideoCategory(media: VideoCategory): [string, Array<string>] {
    const title = pathToCategories(media.category_path)[0];
    const subtitle = [media.category_path];
    this.category = media.category_path.split('.')[0];
    return [title, subtitle];
  }

  private initFromMeeting(media: Meeting): [string, Array<string>] {
    const title = pathToCategories(media.content.categories[0])[0] || '';
    const subtitle = [...media.content.categories];
    this.category = media.content.categories[0].split('.')[0];
    return [title, subtitle];
  }

  private createSubtitle(subtitleBuilder: Array<string>, category: string): Array<string> {
    return Array.from(
      new Set(
        subtitleBuilder
          .filter(subtitle => subtitle != category)
          .map(subtitle => categoryPathToCategory(subtitle.split('.').reverse()[0] || '').trim())
          .filter(category => category)
      )
    ).splice(0, 7);
  }

  private changeFontSize(): void {
    const cover = this.getNativeElement();
    if (!cover) {
      return;
    }
    const width = cover.clientWidth;
    const title = cover.querySelector('.live-title') as HTMLElement;
    const subtitle = cover.querySelector('.live-subtitle') as HTMLElement;
    if (title) title.style.fontSize = (width / 100) * 3.5 + 'px';
    if (subtitle) subtitle.style.fontSize = (width / 100) * 4.5 + 'px';
  }

  private customGeometry(): void {
    const cover = this.getNativeElement();
    if (!cover) {
      return;
    }
    const circle_size = this.addNoise(35, 20);
    const triangle_size = this.addNoise(40, 10);
    const square_size = this.addNoise(25, 10);

    const circle_opacity = this.addNoise(0.6, 0.2, 0.1);
    const triangle_opacity = this.addNoise(0.4, 0.3, 0.35);
    const square_opacity = this.addNoise(0.4, 0.3, 0.35);

    const square_rotate = this.addNoise(15, 15);
    const square_translate = this.addNoise(70, 25);

    const triangle_translate = this.addNoise(-40, 25);

    cover.style.setProperty('--circle-size', circle_size + '%');
    cover.style.setProperty('--triangle-size', triangle_size + '%');
    cover.style.setProperty('--square-size', square_size + '%');

    cover.style.setProperty('--circle-opacity', circle_opacity + '');
    cover.style.setProperty('--triangle-opacity', triangle_opacity + '');
    cover.style.setProperty('--square-opacity', square_opacity + '');

    cover.style.setProperty('--square-rotate', square_rotate + 'deg');
    cover.style.setProperty('--square-translate', square_translate + '%');

    cover.style.setProperty('--triangle-translate', triangle_translate + '%');

    this.geometryPositionHover(cover);
  }

  private getNativeElement() {
    return this.videoCover?.nativeElement;
  }

  private addNoise(value: number, noiseSize: number, min = -Infinity): number {
    return Math.max(value + (Math.random() * noiseSize - noiseSize / 2), min);
  }
}
